

export class Menu {

    _dom = {
        wrapper: null,
        mobileToggleBtn: null,
    }

    _state = {
        isExpanded: false,
    }

    constructor() {
        const wrapper = document.getElementById('site-navigation')

        if (!wrapper) return;

        this._findDomNodes(wrapper)
        this._initListeners()
    }

    _findDomNodes(wrapper) {
        this._dom.wrapper = wrapper
        this._dom.mobileToggleBtn = document.getElementById('mobile-menu-toggle')
        this._dom.menuItem = document.querySelectorAll('.menu-item')
    }

    _initListeners() {
        this._dom.mobileToggleBtn?.addEventListener('click', () => this.toggleMenu())
        this._dom.menuItem.forEach(menuItem => {
            menuItem.addEventListener('click', () => {
                this.closeMenu();
            });
        });

    }
    /**
     * Only on desktop AND if the user starts using the navigation we need the preview images. Therefore, only
     * when the nav is hovered, we replace the data-src with src. Afterwords, we can remove the event lister as we only
     * need to do that once
     */

    toggleMenu() {
        if (this._state.isExpanded) this.closeMenu()
        else this.openMenu()
    }

    openMenu() {
        this._state.isExpanded = true
        this._dom.mobileToggleBtn.setAttribute('aria-expanded', true)
        this._dom.wrapper.classList.add('-toggled')
        document.querySelector('.site-header').classList.add('-toggled')
    }

    closeMenu() {
        this._state.isExpanded = false
        this._dom.mobileToggleBtn.setAttribute('aria-expanded', 'false')
        this._dom.wrapper.classList.remove('-toggled')
        document.querySelector('.site-header').classList.remove('-toggled')
    }
}

