import Splide from "../../../assets/node_modules/@splidejs/splide";

export function initComponentSplideServices() {
    if (window.innerWidth < 768) {
        const mobileServices = document.querySelectorAll(".splide-mobile.standard-teaser");
        mobileServices.forEach((element) => {
            element.classList.add('splide');
            new ComponentSplideService(element);
        });
    }
}


export class ComponentSplideService {

    _dom = {
        slider: null,
        modalTrigger: null, // Add modalTrigger to _dom
    };

    _splide = null;

    constructor(el) {
        if (!el) return;

        this._dom.slider = el;

        this._splide =  new Splide(el, {
            autoHeight: false,
            type: 'slide',
            gap: '1.5rem',
            pagination: false,
            arrowPath: 'm10.01,12.76L.47,22.49c-.29.29-.45.68-.47,1.1-.02.42.12.82.38,1.13l.34.36c.58.58,1.46.58,2.05-.01l10.93-11.15c.29-.29.45-.68.47-1.1s-.12-.83-.38-1.13L2.77.44c-.29-.29-.66-.44-1.02-.44s-.74.15-1.03.44l-.25.26C-.12,1.29-.16,2.29.38,2.93l9.63,9.83Z',
            focus    : 0,
            omitEnd  : true,
            arrows : true,
            perPage: 1,
            autoplay: false,
            speed: 500,
        }).mount()
        this._initListeners();
    }

    _initListeners() {
    }

}