export class Cf7 {
    _dom = {
        wrapper: null,
        checkBoxes: [],
        forms: []
    };

    constructor() {
        const wrapper = document.querySelector('.contact');

        if (!wrapper) return;

        this._findDomNodes(wrapper);
        this._initListeners();
    }

    _findDomNodes(wrapper) {
        this._dom.wrapper = wrapper;
        this._dom.checkBoxes = wrapper.querySelectorAll('input[type="checkbox"]');
        this._dom.forms = wrapper.querySelectorAll('.wp7');

        console.log(this._dom.forms);

        // Initially, no checkbox is checked and no form is active
        this._dom.checkBoxes.forEach(cb => (cb.checked = false));
        this._dom.forms.forEach(form => form.classList.remove('-active'));
    }

    _initListeners() {
        this._dom.checkBoxes.forEach((checkBox, index) => {
            checkBox.addEventListener('click', () => {
                // Deactivate all forms and checkboxes
                this._dom.forms.forEach(form => form.classList.remove('-active'));
                this._dom.checkBoxes.forEach(cb => (cb.checked = false));

                // Activate the clicked checkbox and its corresponding form
                checkBox.checked = true;
                this._dom.forms[index].classList.add('-active');
            });
        });
    }
}
