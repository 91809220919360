export class BackToTop {
    _dom = {
        wrapper: null,
        backToTopWrapper: null,
    }

    constructor() {
        const wrapper = document.querySelector('body');

        if (!wrapper) return;

        this._findDomNodes(wrapper);
        this._initListeners();
    }

    _findDomNodes(wrapper) {
        this._dom.wrapper = wrapper;
        this._dom.backToTopWrapper = document.querySelector('.back-to-top__wrapper');
    }

    _initListeners() {
        if (this._dom.backToTopWrapper) {
            // Add a scroll event listener to the window for scrolling detection
            window.addEventListener('scroll', () => this.onScroll());
        }
    }

    onScroll() {
        // Use this._dom.backToTopWrapper in your function
        if (window.scrollY > 400) {
            this._dom.backToTopWrapper.classList.remove('hidden');
        } else {
            this._dom.backToTopWrapper.classList.add('hidden');
        }
    }
}
