import Splide from "../../../assets/node_modules/@splidejs/splide";

export function initComponentSplideFeedback() {
    [...document.querySelectorAll(".splide.feedbackslider")].map((el) => new ComponentSplideFeedback(el));
}


export class ComponentSplideFeedback {

    _dom = {
        slider: null,
    };

    _splide = null;
    _currentSlide = 0;

    constructor(el) {
        if (!el) return;

        this._dom.slider = el;

        this._splide =  new Splide(el, {
            type: 'loop',
            gap: '6rem',
            pagination: false,
            focus    : 0,
            omitEnd  : true,
            arrowPath: 'm10.01,12.76L.47,22.49c-.29.29-.45.68-.47,1.1-.02.42.12.82.38,1.13l.34.36c.58.58,1.46.58,2.05-.01l10.93-11.15c.29-.29.45-.68.47-1.1s-.12-.83-.38-1.13L2.77.44c-.29-.29-.66-.44-1.02-.44s-.74.15-1.03.44l-.25.26C-.12,1.29-.16,2.29.38,2.93l9.63,9.83Z',
            arrows: true,
            pauseOnHover: false,

            breakpoints: {
                3800: {
                    padding: { left: '15%', right: "15%" },
                    perPage: 2,
                },
                1280: {
                    perPage: 1,
                    padding: { left: '30%', right: "30%" },
                },
                768: {
                    gap: '1.25rem',
                    padding: '10%',
                },
            },
            // autoplay: 300,
            // interval: 6000,
            speed: 3000,
            // autoplay: 200000,
            // interval: 170000,
        }).mount()

        this._initListeners();
    }

    _initListeners() {
        this._splide.on('move', (newIndex) => {
            this._currentSlide = newIndex;
        });
    }

}